// 
// timeline.scss
//

.timeline {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    margin-bottom: 50px;
    position: relative;
    table-layout: fixed;
    width: 100%;
  
    .time-show {
      margin-bottom: 30px;
      margin-right: -75px;
      margin-top: 30px;
      position: relative;
      text-align: right;
      a {
        color: $white;
      }
    }
    &:before {
      background-color: rgba($gray-500, 0.3);
      bottom: 0px;
      content: "";
      left: 50%;
      position: absolute;
      top: 30px;
      width: 2px;
      z-index: 0;
    }
    .timeline-icon {
      -webkit-border-radius: 50%;
      background: $gray-600;
      border-radius: 50%;
      color: $white;
      display: block;
      height: 21px;
      left: -54px;
      margin-top: -11px;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 21px;
      i {
        color: $gray-200;
      }
    }
    .time-icon {
      &:before {
        font-size: 16px;
        margin-top: 5px;
      }
    }
  
  }
  
  h3{
    &.timeline-title {
      color: $gray-600;
      font-size: 20px;
      font-weight: 400;
      margin: 0 0 5px;
      text-transform: uppercase;
    }
  }
  
  .timeline-item {
    display: table-row;
    &:before {
      content: "";
      display: block;
      width: 50%;
    }
    .timeline-desk {
      .arrow {
        border-bottom: 8px solid transparent;
        border-right: 8px solid $card-bg !important;
        border-top: 8px solid transparent;
        display: block;
        height: 0;
        left: -7px;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }
  
  .timeline-item.alt {
    &:after {
      content: "";
      display: block;
      width: 50%;
    }
    .timeline-desk {
      .arrow-alt {
        border-bottom: 8px solid transparent;
        border-left: 8px solid $card-bg !important;
        border-top: 8px solid transparent;
        display: block;
        height: 0;
        left: auto;
        margin-top: -10px;
        position: absolute;
        right: -7px;
        top: 50%;
        width: 0;
      }
      .album {
        float: right;
        margin-top: 20px;
        a {
          float: right;
          margin-left: 5px;
        }
      }
    }
    .timeline-icon {
      left: auto;
      right: -58px;
    }
    &:before {
      display: none;
    }
    .panel {
      margin-left: 0;
      margin-right: 45px;
      .panel-body p + p {
        margin-top: 10px !important;
      }
    }
    h4 {
      text-align: right;
    }
    p {
      text-align: right;
    }
    .timeline-date {
      text-align: right;
    }
  }
  
  .timeline-desk {
    display: table-cell;
    vertical-align: top;
    width: 50%;
    h4 {
      font-size: 16px;
      font-weight: 300;
      margin: 0;
    }
    .panel {
      background: $card-bg;
      display: block;
      margin-bottom: 5px;
      margin-left: 45px;
      position: relative;
      text-align: left;
      padding: 20px;
      border-radius: 7px;
    }
    h5 {
      span {
        color: $gray-200;
        display: block;
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
    p {
      color: $gray-600;
      font-size: 14px;
      margin-bottom: 0;
    }
    .album {
      margin-top: 12px;
      a {
        float: left;
        margin-right: 5px;
      }
  
      img {
        height: 36px;
        width: auto;
        border-radius: 3px;
      }
    }
    .notification {
      background: none repeat scroll 0 0 $white;
      margin-top: 20px;
      padding: 8px;
    }
  }