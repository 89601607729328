//
// slimscroll.scss
//

.slimScrollDiv {
    height: auto !important;
}

.slimScrollBar{
    width: 6px !important;
}